
import jquery from 'jquery';
import Swiper from 'swiper/swiper-bundle.min';
import iziToast from "izitoast/dist/js/iziToast.min";

window.$ = window.jQuery = jquery;
window.Swiper = Swiper;
require('../bootstrap');
window.iziToast = iziToast;

require('@fancyapps/fancybox/dist/jquery.fancybox.min');

//image gallery
$('[data-fancybox="gallery"]').fancybox({
    buttons: [
        "slideShow",
        "thumbs",
        "zoom",
        "fullScreen",
        "close"
    ],
    loop: false,
    protect: true
});
$(window).scroll(function(){
    if ($(this).scrollTop() > 1) {
        $('#menu').addClass('fixed');
    } else {
        $('#menu').removeClass('fixed');
    }

    $('.section').each(function(i) {
        if ($(this).position().top <= $(window).scrollTop()) {
            $('.nav-item.active').removeClass('active');
            $('.nav-item').eq(i).addClass('active');
        }
    });
});
$(window).scroll(function() {
    if ($(window).scrollTop() > 300) {
        $('#back-to-top').addClass('show');
    } else {
        $('#back-to-top').removeClass('show');
    }
});
$(document).ready(function() {
    $('#nav-phone').click(function() {
        $('#menu').addClass('show');
    });
    $('.icon-close').click(function(){
        $('#menu').removeClass('show')
    });

    $('#back-to-top').on('click', function(e) {
        e.preventDefault();
        $('html, body').animate({scrollTop:0}, '500');
    });
});
